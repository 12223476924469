<template>
    <div class="tag-manager">
        <el-breadcrumb class="crumb" separator="/">
            <el-breadcrumb-item>标签管理</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="tag-page">
            <div class="tag-opts">
                <el-alert  class="alert" title="提示: 主子账号共用一套标签，若删除标签会影响其他账号使用"
                          type="warning" show-icon :closable="false"></el-alert>
                <el-input placeholder="请输入内容" v-model="keyword" style="max-width: 200px;">
                    <el-button slot="append" icon="el-icon-search" @click="loadData"></el-button>
                </el-input>
            </div>

            <el-table :row-key="row => row.bid+row.commonUse" :data="tableData" v-loading="loadingData" style="width: 100%">
                <el-table-column prop="name" label="标签名称" width="380px">
                </el-table-column>
                <el-table-column prop="commonUse" label="常用标签">
                    <template slot-scope="{row}">
                        <el-checkbox :value="row.commonUse === 'true'" @change="handleCommonUse($event, row)"/>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="操作" width="147px" v-if="accountType === 'main'">
                    <template slot-scope="{row}">
                        <el-button type="text" @click="handleDelete(row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <div class="bs-pager" >
                <Pager v-model="pager" :sizes="[5, 10, 20, 50, 100]" @currentChange="handleCurrentChange"
                       @sizeChange="handleSizeChange"></Pager>
            </div>
        </div>
    </div>
</template>

<script>
import setTag from "@/views/bucket/components/setTag";
import {mapGetters} from "vuex";

export default {
    name: "Tag",
    components: {setTag},
    data() {
        return {
            keyword: '',
            tableData: [],
            loadingData: false,
            pager: {
                current: 1,
                size: 10,
                total: 0,
            },
        }
    },
    computed: {
        ...mapGetters(['accountType'])
    },
    mounted() {
        this.loadData()
    },
    methods: {
        changeTag (list) {
            console.log(list)
        },
        // 删除标签
        handleDelete (row) {
            this.$confirm("确认要删除吗？", {
                confirmButtonText: "确定",
                type: "warning",
                showClose: false,
                closeOnClickModal: false
            }).then(res => {
                this.$postJson('/tag/delete', {tagIdList: [row.bid]})
                    .then(res => {
                        if (res.code === '200') {
                            this.$message.success('已删除')
                            this.pager.current = 1
                            this.loadData()
                        }
                    })
            })
        },
        // 设置常用标签
        handleCommonUse (e, row) {
            this.$confirm("确认要修改吗？", {
                confirmButtonText: "确定",
                type: "warning",
                showClose: false,
                closeOnClickModal: false
            }).then(res => {
                this.$postJson('/tag/update', {bid: row.bid, commonUse: e})
                    .then(res => {
                        if (res.code === '200') {
                            this.loadData()
                        }
                    })
            }).catch(() => {
                this.loadData()
            })
        },
        loadData () {
            let data = {pageNum: this.pager.current, pageSize: this.pager.size}
            if (this.keyword) {
                data.name = this.keyword
            }
            this.$postJson('/tag/listPage', data)
            .then(res => {
                if (res.code === '200') {
                    this.tableData = res.data.records
                    this.pager.total = res.data.total
                }
            })
        },
        handleSizeChange(size) {
            this.pager.current = 1;
            this.pager.size = size;
            this.loadData();
        },
        handleCurrentChange(current) {
            this.pager.current = current;
            this.loadData();
        },
    }
}
</script>

<style scoped lang="scss">
.tag-manager {
    min-height: calc(100% - 36px);
    margin: 16px 16px 10px;
    padding: 0 20px;
    background: #fff;
    text-align: left;

    .el-select .el-input .el-select__caret {
        line-height: 28px;
    }
}

.tag-manager .el-breadcrumb__inner {
    font-size: 16px !important;
    color: #031129 !important;
}

.tag-opts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 0;
}
</style>